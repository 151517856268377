import '../styles/home.scss';

import { removeParamFromUrl } from './lib/utils/url';

(function removeNotificationMessageParam() {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const paramName = 'reason';
    if (url.searchParams.get(paramName)) {
        let strippedUrl = removeParamFromUrl(paramName, currentUrl);
        window.history.replaceState({}, document.title, strippedUrl);
    }
}());
